import { render, staticRenderFns } from "./TheIntegration.vue?vue&type=template&id=230bcf53&scoped=true&"
import script from "./TheIntegration.vue?vue&type=script&lang=js&"
export * from "./TheIntegration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "230bcf53",
  null
  
)

export default component.exports